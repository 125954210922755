<template>
  <div>
    <v-header class="headerTop"></v-header>
    <div class="listWOW">
      <div class="row">
        <div class="slideInLeft">
          <div class="wow rollIn"><img src="../../assets/images/product/exam/exam_01.png"/></div>
        </div>
        <div class="slideInRight">
          <div class="wow bounceInDown"><img src="../../assets/images/product/exam/exam_01_title.png"/></div>
          <div class="wow lightSpeedIn cursorHand  wowMargin">
            <a href="http://www.51shouyu.com" target="_blank">
              <img src="../../assets/images/online.png"/>
            </a>
          </div>
        </div>
      </div>
      <div class="row" style="background-color: #5090bd;">
        <div class="slideInLeft">
          <div class="wow bounceInLeft"><img src="../../assets/images/product/exam/exam_02.png"/></div>
        </div>
        <div class="slideInRightN flipInX">
          <div class="wow flipInX"><img src="../../assets/images/product/exam/exam_02_title.png"/></div>
        </div>
      </div>
      <div class="row" style="background-color: #7aafd3;">
        <div class="slideInRight">
          <div class="wow bounceInLeft"><img src="../../assets/images/product/exam/exam_03_title.png"/></div>
        </div>
        <div class="slideInLeft">
          <div class="wow bounceInRight"><img src="../../assets/images/product/exam/exam_03.png"/></div>
        </div>
      </div>
      <div class="row-column" style="background-color: #5090bd;">
        <div class="row">
          <div class="wow bounceInLeft"><img src="../../assets/images/product/exam/exam_04.png"/></div>
          <div class="wow bounceInRight "><img src="../../assets/images/product/exam/exam_04_title.png" width="80%"/></div>
        </div>
        <div class="wow columnW-min cursorHand" style="margin-bottom: 1.2rem"><img src="../../assets/images/btn_source.png"/></div>
      </div>
    </div>
    <v-footer></v-footer>
  </div>

</template>

<script>
  import Header from '../../components/header';
  import Hfooter from '../../components/Hfooter';

  export default {
    name: 'Exam',
    components: {
      'v-header': Header,
      'v-footer': Hfooter
    }
  };
</script>

<style>
</style>
